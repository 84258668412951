import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import {Row, Col, Container } from 'react-bootstrap';
import "../Banner/banner.scss"
import ReactMarkdown from "react-markdown"
import Slider from 'react-slick';
import SearchInputBox from '../../predictive-search/search-inputbox';
import $ from 'jquery'
import { navigate } from "@reach/router"
import { getSrc } from "gatsby-plugin-image"
import { isMobile, browserName } from "react-device-detect";

import CTA from './banner-cta';

// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const SliderNav = (props) => {
  let [nav1, setNav1] = useState(null)
  let [nav2, setNav2] = useState(null)
  let [areaVal, setAreaVal] = useState("")

  const settings = props.settings
  const thumbsetting = props.thumbsetting

//  console.log("autoplay", props.autoplay)

  useEffect(() => {
    // Property search results
    let url = "/";

    $(".search_btn").click(function() {
      var searcValue = $(".property_search_banner .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
      // var searcValue = areaVal;

      var price = $(".price-filter-dropdown").val()
      var rent_price = $(".rent-price-filter-dropdown").val()
      var ptype = $(".ptype").val()
      if(ptype == 'buy') {
        url = "/property/for-sale/"
      }
      else {
        url = "/property/to-rent/"
      }

      if(searcValue !== "") {
          url += "in-"+searcValue+"/"
      } else {
          url += "in-london/"
      }

      if(price !== "0" && ptype == 'buy') {
          url += "under-"+price+"/"
      }
      if(rent_price !== "0" && ptype == 'rent') {
          url += "under-"+rent_price+"/"
      }

      navigate(url);
  })

  $(".search_btn_mobile").click(function() {
    var searcValue = $(".property_search_banner_mobile .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
    // var searcValue = areaVal;

    var price = $(".price-filter-dropdown_mobile").val()
    var rent_price = $(".rent-price-filter-dropdown_mobile").val()
    var ptype = $(".ptype_mobile").val()
    if(ptype == 'buy') {
      url = "/property/for-sale/"
    }
    else {
      url = "/property/to-rent/"
    }

    if(searcValue !== "") {
        url += "in-"+searcValue+"/"
    } else {
        url += "in-london/"
    }

    if(price !== "0" && ptype == 'buy') {
        url += "under-"+price+"/"
    }
    if(rent_price !== "0" && ptype == 'rent') {
        url += "under-"+rent_price+"/"
    }

    navigate(url);
  })

    //$(".sales_btn").click(function() {
        //var searcValue = $(".property_search_banner .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
        //var price = $(".price-filter-dropdown").val()
        //alert(price)
        //if(searcValue !== "") {
            //url = "in-"+searcValue+"/"
        //} else {
            //url = "in-london/"
        //}
        //navigate("/property/for-sale/"+url);
    //})

    //$(".rent_btn").click(function() {
        //var searcValue = $(".property_search_banner .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
        //if(searcValue !== "") {
            //url = "in-"+searcValue+"/"
        //} else {
            //url = "in-london/"
        //}
        //navigate("/property/to-rent/"+url);
    //})


  $('.react-autosuggest__input').on('click', function() {
      if(props.handleChange) {
      props.handleChange("stop");
      }
  });




    // Property search results
  }, [areaVal])

  function slider_stop(e){
      if(props.handleChange) {
      props.handleChange("stop");
      }

      var ptype = $(".ptype").val()
      var ptype_mble = $(".ptype_mobile").val()
      if(ptype === 'buy') {
        $(".sales_price_list").show();
        $(".rent_price_list").hide();
      }
      else {
        $(".sales_price_list").hide();
        $(".rent_price_list").show();
      }

      if(ptype_mble === 'buy') {
        $(".price-filter-dropdown_mobile").show();
        $(".rent-price-filter-dropdown_mobile").hide();
      }
      else {
        $(".price-filter-dropdown_mobile").hide();
        $(".rent-price-filter-dropdown_mobile").show();
      }
  }

  //console.log("areaVal==>", areaVal)

  return(
    <>
        <div className="home-slider-banner">
        <Slider
          className="banner-slider"
          {...settings}
          asNavFor={nav2}
          ref={slider => setNav1(slider)}
        >
          {props.data.map((item, i) => {
            let url;
            let image = getSrc(item.Image)
            let mobile_image = getSrc(item.Mobile_Image)
            return(
            <div className="banner-item" key={i}>
            <div className="desktop-banner-search-block desk-block">
              <picture>
                <source media="(min-width:1699px)" srcset={image} />
                <source media="(min-width:992px)" srcset={image} />
                <source media="(min-width:768px)" srcset={image} />
                <img variants={imageVariants} className="banner-img" src={image} alt={`${props.page} ${item.Title} banner- Martyn Gerrard`}></img>
              </picture>
              </div>

            <div className="mobile-banner-search-block mob-block">
              <picture>
                <source media="(min-width:1699px)" srcset={item.Mobile_Image ? mobile_image : image} />
                <source media="(min-width:992px)" srcset={item.Mobile_Image ? mobile_image : image} />
                <source media="(min-width:768px)" srcset={item.Mobile_Image ? mobile_image : image} />
                <img variants={imageVariants} className="banner-img" src={item.Mobile_Image ? mobile_image : image} alt={`${props.page} ${item.Title} banner- Martyn Gerrard`}></img>
              </picture>
              </div>

                <div className={`banner-content ${item.Title === "70 Bags for 70 Years" ? "cta-bottom" : ""}`}>
                  <Container>
                    <Row>
                      {item.Content ? <Col xs={12} sm={12} md={12} lg={9} xl={9}><ReactMarkdown source={item.Content} allowDangerousHtml /></Col> : ''}
                      { i == 0 ?
                      <>
                        <Col md={12}>


                         <div className="desktop-banner-search-block">

                          <div className="input-group property-search-banner">
                              <select className="search-select ptype" id="search_dropdown" onClick={slider_stop}>
                                  <option value="buy">Buy</option>
                                  <option value="rent">Rent</option>
                              </select>
                              <div className="input-group-prepend property-search-banner-width property-search_banner property_search_banner">
                                  <SearchInputBox handleChange={props.handleChange} setAreaVal={(val) => setAreaVal(val)} type="home-search"/>
                              </div>
                              <div>
                              <select className="search-select sales_price_list price-filter-dropdown" id="search_dropdown" onClick={slider_stop}>
                                <option value="0">Max Price</option>
                                <option value="4500000">£4,500,000 +</option>
                                <option value="4250000">£4,250,000</option>
                                <option value="4000000">£4,000,000</option>
                                <option value="3750000">£3,750,000</option>
                                <option value="3500000">£3,500,000</option>
                                <option value="3250000">£3,250,000</option>
                                <option value="3000000">£3,000,000</option>
                                <option value="2750000">£2,750,000</option>
                                <option value="2500000">£2,500,000</option>
                                <option value="2250000">£2,250,000</option>
                                <option value="2000000">£2,000,000</option>
                                <option value="1900000">£1,900,000</option>
                                <option value="1800000">£1,800,000</option>
                                <option value="1700000">£1,700,000</option>
                                <option value="1600000">£1,600,000</option>
                                <option value="1500000">£1,500,000</option>
                                <option value="1400000">£1,400,000</option>
                                <option value="1300000">£1,300,000</option>
                                <option value="1200000">£1,200,000</option>
                                <option value="1100000">£1,100,000</option>
                                <option value="1000000">£1,000,000</option>
                                <option value="900000">£900,000</option>
                                <option value="875000">£875,000</option>
                                <option value="850000">£850,000</option>
                                <option value="825000">£825,000</option>
                                <option value="800000">£800,000</option>
                                <option value="775000">£775,000</option>
                                <option value="750000">£750,000</option>
                                <option value="725000">£725,000</option>
                                <option value="700000">£700,000</option>
                                <option value="675000">£675,000</option>
                                <option value="650000">£650,000</option>
                                <option value="625000">£625,000</option>
                                <option value="600000">£600,000</option>
                                <option value="575000">£575,000</option>
                                <option value="550000">£550,000</option>
                                <option value="525000">£525,000</option>
                                <option value="500000">£500,000</option>
                                <option value="475000">£475,000</option>
                                <option value="450000">£450,000</option>
                                <option value="425000">£425,000</option>
                                <option value="400000">£400,000</option>
                                <option value="375000">£375,000</option>
                                <option value="350000">£350,000</option>
                                <option value="325000">£325,000</option>
                                <option value="300000">£300,000</option>
                                <option value="275000">£275,000</option>
                                <option value="250000">£250,000</option>
                                <option value="225000">£225,000</option>
                                <option value="200000">£200,000</option>
                              </select>
                              <select className="search-select rent_price_list rent-price-filter-dropdown" id="search_dropdown" onClick={slider_stop}>
                                <option value="0">Max Price</option>
                                <option value="10000">£10,000 pcm +</option>
                                <option value="5000">£5,000 pcm</option>
                                <option value="4500">£4,500 pcm</option>
                                <option value="4000">£4,000 pcm</option>
                                <option value="3500">£3,500 pcm</option>
                                <option value="3000">£3,000 pcm</option>
                                <option value="2500">£2,500 pcm</option>
                                <option value="2000">£2,000 pcm</option>
                                <option value="1500">£1,500 pcm</option>
                                <option value="1000">£1,000 pcm</option>
                                <option value="500">£500 pcm</option>
                              </select>
                              </div>
                              <div className="property-search-banner-btn_wrapper">
                                  <a href="javascript:void(0)" className="btn search_btn">Search</a>
                                  {/*<a href="javascript:void(0)" className="btn sales_btn">Buy</a>
                                  <a href="javascript:void(0)" className="btn rent_btn">Rent</a> */}
                              </div>
                          </div>

                          </div>
                          <div className="mobile-banner-search-block">

                          <div className="input-group property-search-banner property-search-banner_mobile mobile">
                            <div className="row">
                              <div className="col-xs-6 cols-1">
                                <select className="search-select ptype ptype_mobile" id="search_dropdown" onClick={slider_stop}>
                                    <option value="buy">Buy</option>
                                    <option value="rent">Rent</option>
                                </select>
                              </div>
                              <div className="col-xs-6 cols-2">
                              <select className="search-select sales_price_list price-filter-dropdown price-filter-dropdown_mobile" id="search_dropdown" onClick={slider_stop}>
                                <option value="0">Max Price</option>
                                <option value="4500000">£4,500,000 +</option>
                                <option value="4250000">£4,250,000</option>
                                <option value="4000000">£4,000,000</option>
                                <option value="3750000">£3,750,000</option>
                                <option value="3500000">£3,500,000</option>
                                <option value="3250000">£3,250,000</option>
                                <option value="3000000">£3,000,000</option>
                                <option value="2750000">£2,750,000</option>
                                <option value="2500000">£2,500,000</option>
                                <option value="2250000">£2,250,000</option>
                                <option value="2000000">£2,000,000</option>
                                <option value="1900000">£1,900,000</option>
                                <option value="1800000">£1,800,000</option>
                                <option value="1700000">£1,700,000</option>
                                <option value="1600000">£1,600,000</option>
                                <option value="1500000">£1,500,000</option>
                                <option value="1400000">£1,400,000</option>
                                <option value="1300000">£1,300,000</option>
                                <option value="1200000">£1,200,000</option>
                                <option value="1100000">£1,100,000</option>
                                <option value="1000000">£1,000,000</option>
                                <option value="900000">£900,000</option>
                                <option value="875000">£875,000</option>
                                <option value="850000">£850,000</option>
                                <option value="825000">£825,000</option>
                                <option value="800000">£800,000</option>
                                <option value="775000">£775,000</option>
                                <option value="750000">£750,000</option>
                                <option value="725000">£725,000</option>
                                <option value="700000">£700,000</option>
                                <option value="675000">£675,000</option>
                                <option value="650000">£650,000</option>
                                <option value="625000">£625,000</option>
                                <option value="600000">£600,000</option>
                                <option value="575000">£575,000</option>
                                <option value="550000">£550,000</option>
                                <option value="525000">£525,000</option>
                                <option value="500000">£500,000</option>
                                <option value="475000">£475,000</option>
                                <option value="450000">£450,000</option>
                                <option value="425000">£425,000</option>
                                <option value="400000">£400,000</option>
                                <option value="375000">£375,000</option>
                                <option value="350000">£350,000</option>
                                <option value="325000">£325,000</option>
                                <option value="300000">£300,000</option>
                                <option value="275000">£275,000</option>
                                <option value="250000">£250,000</option>
                                <option value="225000">£225,000</option>
                                <option value="200000">£200,000</option>
                              </select>
                              <select className="search-select rent_price_list rent-price-filter-dropdown rent-price-filter-dropdown_mobile" id="search_dropdown" onClick={slider_stop}>
                                <option value="0">Max Price</option>
                                <option value="10000">£10,000 pcm +</option>
                                <option value="5000">£5,000 pcm</option>
                                <option value="4000">£4,000 pcm</option>
                                <option value="3000">£3,000 pcm</option>
                                <option value="2000">£2,000 pcm</option>
                                <option value="1000">£1,000 pcm</option>
                              </select>
                              </div>
                            </div>
                              <div className="input-group-prepend property-search-banner-width property-search_banner property_search_banner_mobile">
                                  <SearchInputBox handleChange={props.handleChange} setAreaVal={(val) => setAreaVal(val)}  type="home-search"/>
                              </div>
                              <div>
                              </div>
                              <div className="property-search-banner-btn_wrapper">
                                  <a href="javascript:void(0)" className="btn search_btn search_btn_mobile">Search</a>
                              </div>
                          </div>
                          </div>

                        </Col>
                        <Col md={10} className="cta-valuation"> 
                          <Link className="btn" to={`/market-your-property/book-a-valuation/`}>Book a valuation</Link>
                        </Col>
                      </>
                      :
                      <Col md={12} className="cta-banner-btn-align">{item.Title === "70 Bags for 70 Years" ? <Link className="btn" to={`/news-and-guides/property-news/local-area-news/food-bank-aid-north-london-70-for-70-campaign/`}>{item.CTA_1_Label}</Link> : item.CTA_1_Label ? item.CTA_1_Label === "HIDE_CTA" ? "" :
                      <CTA label={item.CTA_1_Label} url={item.CTA_1_URL}/> : ''}</Col>
                      }
                    </Row>
                  </Container>
                </div>
            </div>
            )
          })}
        </Slider>
        {props.data.length && props.data.length > 1 &&
        <div className="thumbnails">
          <Container>
            <Slider
              className="thumb-slider"
              {...thumbsetting}
              asNavFor={nav1}
              ref={slider => setNav2(slider)}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              {props.data.map((item, i) => {
                return <div className="thumbitem" kay={i}>
                  <span className="slide-line d-block"></span>
                  <p className="d-none d-md-block">{item.Title}</p>
                </div>
              })}
            </Slider>
          </Container>
        </div>}
      </div>
    </>
  )
}

export default SliderNav
