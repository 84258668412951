
const postCode = [
    {
     "postcode": "N1",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "AnnieC@martyngerrard.co.uk",
     "email3": "nw5@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N2",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "andreasp@martyngerrard.co.uk",
     "email3": "n2@martyngerrard.co.uk",
     "lettings": "panikosk@martyngerrard.co.uk"
    },
    {
     "postcode": "N3",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "zlatt@martyngerrard.co.uk",
     "email3": "n3@martyngerrard.co.uk",
     "lettings": "tylerg@martyngerrard.co.uk"
    },
    {
     "postcode": "N4",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "jamesm@martyngerrard.co.uk",
     "email3": "n8@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N5",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "AnnieC@martyngerrard.co.uk",
     "email3": "nw5@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N6",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "jamesm@martyngerrard.co.uk",
     "email3": "n8@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N7",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "AnnieC@martyngerrard.co.uk",
     "email3": "nw5@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N8",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "jamesm@martyngerrard.co.uk",
     "email3": "n8@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N10",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "chrisa@martyngerrard.co.uk",
     "email3": "n10@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "N11",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "craigt@martyngerrard.co.uk",
     "email3": "N12@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "N12",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "craigt@martyngerrard.co.uk",
     "email3": "N12@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "N13",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "DineshM@martyngerrard.co.uk",
     "email3": "N21@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "N14",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "DineshM@martyngerrard.co.uk",
     "email3": "N21@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "N19",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "jamesm@martyngerrard.co.uk",
     "email3": "n8@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "N20",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "stewartw@martyngerrard.co.uk",
     "email3": "n20@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "N21",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "dineshm@martyngerrard.co.uk",
     "email3": "N21@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "N22",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "chrisa@martyngerrard.co.uk",
     "email3": "n10@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "EN2",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "DineshM@martyngerrard.co.uk",
     "email3": "N21@martyngerrard.co.uk",
     "lettings": "faisala@martyngerrard.co.uk"
    },
    {
     "postcode": "EN4",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "bruvisw@martyngerrard.co.uk",
     "email3": "en5@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "EN5",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "bruvisw@martyngerrard.co.uk",
     "email3": "en5@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "EN6",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "bruvisw@martyngerrard.co.uk",
     "email3": "en5@martyngerrard.co.uk",
     "lettings": "andrewe@martyngerrard.co.uk"
    },
    {
     "postcode": "NW1",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "AnnieC@martyngerrard.co.uk",
     "email3": "nw5@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "NW3",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "chrisf@martyngerrard.co.uk",
     "email3": "nw3@martyngerrard.co.uk",
     "lettings": "panikosk@martyngerrard.co.uk"
    },
    {
     "postcode": "NW4",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "davidm@martyngerrard.co.uk",
     "email3": "nw7@martyngerrard.co.uk",
     "lettings": "tylerg@martyngerrard.co.uk"
    },
    {
     "postcode": "NW5",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "AnnieC@martyngerrard.co.uk",
     "email3": "nw5@martyngerrard.co.uk",
     "lettings": "anthonys@martyngerrard.co.uk"
    },
    {
     "postcode": "NW7",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "davidm@martyngerrard.co.uk",
     "email3": "nw7@martyngerrard.co.uk",
     "lettings": "tylerg@martyngerrard.co.uk"
    },
    {
     "postcode": "NW11",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "zlatt@martyngerrard.co.uk",
     "email3": "n3@martyngerrard.co.uk",
     "lettings": "tylerg@martyngerrard.co.uk"
    },
    {
     "postcode": "HA8",
     "email1": "ho@martyngerrard.co.uk",
     "sales": "davidm@martyngerrard.co.uk",
     "email3": "nw7@martyngerrard.co.uk",
     "lettings": "tylerg@martyngerrard.co.uk"
    },
    {
     "postcode": "All other postcodes",
     "email1": "ho@martyngerrard.co.uk"
    }
   ]



export const homevisitEmailMatch = (values) => {
    var emailIds = []
    if(values.office === "Land and New Homes"){
        emailIds.push("HO@martyngerrard.co.uk,allclientservices@martyngerrard.co.uk,wp@martyngerrard.co.uk")
        if(values.sell){
            emailIds.push("SaadS@martyngerrard.co.uk")
        }
        if(values.let){
            emailIds.push("SaadS@martyngerrard.co.uk")
        }
        return emailIds
    }
    else if(values.office === "Commercial"){
        emailIds.push("HO@martyngerrard.co.uk,allclientservices@martyngerrard.co.uk")
        if(values.sell){
            emailIds.push("simon@obre.co.uk")
        }
        if(values.let){
            emailIds.push("simon@obre.co.uk")
        }
        return emailIds
    }else{
        postCode.map((item, i) => {
            // item.postcode.map((data, id) => {
                var postCode = item?.postcode
                var userPostCode = values?.postcode?.substring(0, values?.postcode?.indexOf(' '))
                var flag = userPostCode === postCode ? true : false
                if(flag){
                    // emailIds.push(item.othherEmail)
                    if(values.sell && values.let){
                        emailIds.push(item.email1,item.sales,item.email3,item.lettings)
                    }
                    else if(values.sell){
                        emailIds.push(item.sales,item.email1,item.email3)
                    }
                    else if(values.let){
                        emailIds.push(item.lettings,item.email1,item.email3)
                    }
                    
                }
            // })
        
        })
        if(emailIds.length > 0){
            return emailIds
        }
        else{
            emailIds = ["ho@martyngerrard.co.uk"]
            return emailIds
        }
    }


}
